import React from "react"
import { Box, Card, Heading } from "theme-ui"
import Slider from "react-slick"

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  autoplay: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  appendDots: dots => {
    return <Box sx={{ position: "absolute", bottom: "10px" }}>{dots}</Box>
  },
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

interface AnnouncementCardProps {
  text: string
}

const AnnouncementCard = (props: AnnouncementCardProps): JSX.Element => {
  const { text } = props
  return (
    <Card
      variant="shadow2"
      sx={{
        width: "280px",
        minHeight: "125px",
        mx: "auto",
        fontSize: 1,
        borderTop: theme => `5px solid ${theme.colors.primary}`,
      }}
      m={3}
      p={3}
    >
      {text}
    </Card>
  )
}

interface AnnouncementProps {
  data: string[]
}

const Announcements = (props: AnnouncementProps) => {
  const { data } = props

  return (
    <Box
      sx={{
        maxWidth: "100%",
        overflowX: "hidden",
      }}
      data-sal="slide-down"
    >
      <Heading
        mt={4}
        mb={3}
        variant="caps"
        sx={{ textAlign: "center", fontSize: [2, null, 4] }}
      >
        Important Announcements
      </Heading>
      <Box
        pb={3}
        sx={{ maxWidth: "1200px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Slider {...settings}>
          {data.map((item, index) => (
            <AnnouncementCard key={index} text={item} />
          ))}
        </Slider>
      </Box>
    </Box>
  )
}
export default Announcements
