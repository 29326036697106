/** @jsx jsx */
import React from "react"
import { Box, jsx } from "theme-ui"
import Img from "gatsby-image"

interface BannerProps {
  image: any
  alt: string
}

const Banner = (props: BannerProps): JSX.Element => {
  const { image, alt } = props
  return (
    <Box>
      <Img fluid={image} alt={alt} />
    </Box>
  )
}

export default Banner
