/** @jsx jsx */
import React from "react"
import { Box, jsx } from "theme-ui"
import ContentSection from "../ContentSection"
import SpecialityGrid from "../SpecialityGrid"

// images
import SpecialityBackground from "../../../images/SpecialityBackground.svg"

const OurSpecialitySection = () => {
  return (
    <Box mt={-5} pt={2} pb={3} sx={{ width: "100%", position: "relative" }}>
      {/* bg image */}
      <img
        src={SpecialityBackground}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: -1,
          display: ["none", null, null, null, "block"],
        }}
      />
      <ContentSection header="Our Speciality" body={<SpecialityGrid />} />
    </Box>
  )
}
export default OurSpecialitySection
