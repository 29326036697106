import React, { useState } from "react"
import "./index.css"
import Mentors from "../components/Home/Mentors"
import MediaCoverage from "../components/Home/MediaCoverage"
import Cta from "../components/Home/Cta"
import Carousel from "../components/Home/Carousel"
import Banner from "../components/Home/Banner"
import Layout from "../components/Layout"
import NumberSection from "../components/Home/NumberSection"
import HeroFooter from "../components/Home/HeroFooter"
import { Box } from "theme-ui"
import { graphql, navigate } from "gatsby"
import OurSpecialitySection from "../components/Home/OurSpecialitySection"
import NewsAndEvents from "../components/Home/NewsAndEvents"
import Announcements from "../components/Home/Announcements"
import SEO from "../components/SEO"
import ContactSection from "../components/Home/ContactSection"
import Modal from "react-modal"
import mobileModal from "../../images/mobileSlider1.jpg"
import { IoMdClose } from "react-icons/io"
import { useMediaQuery } from "usehooks-ts"
import modal from "../../images/modal.jpg"

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const query = graphql`
  query {
    banner1: file(relativePath: { eq: "slider1.jpg" }) {
      ...fluidImage
    }
    banner2: file(relativePath: { eq: "slider2.jpg" }) {
      ...fluidImage
    }
    banner3: file(relativePath: { eq: "slider3.jpg" }) {
      ...fluidImage
    }
    banner4: file(relativePath: { eq: "slider4.webp" }) {
      ...fluidImage
    }
    banner5: file(relativePath: { eq: "slider5.jpg" }) {
      ...fluidImage
    }

    banner6: file(relativePath: { eq: "slider6.jpg" }) {
      ...fluidImage
    }
    banner7: file(relativePath: { eq: "slider7.jpg" }) {
      ...fluidImage
    }
    banner8: file(relativePath: { eq: "slider8.jpg" }) {
      ...fluidImage
    }
    mobileBanner1: file(relativePath: { eq: "mobileSlider1.jpg" }) {
      ...fluidImage
    }
    mobileBanner2: file(relativePath: { eq: "mobileSlider2.png" }) {
      ...fluidImage
    }
    mobileBanner3: file(relativePath: { eq: "mobileSlider3.jpg" }) {
      ...fluidImage
    }
    mobileBanner4: file(relativePath: { eq: "mobileSlider4.png" }) {
      ...fluidImage
    }
    mobileBanner5: file(relativePath: { eq: "mobileSlider5.png" }) {
      ...fluidImage
    }
    mobileBanner6: file(relativePath: { eq: "mobileSlider6.png" }) {
      ...fluidImage
    }
    mobileBanner7: file(relativePath: { eq: "mobileSlider7.png" }) {
      ...fluidImage
    }
    mobileBanner8: file(relativePath: { eq: "mobileSlider8.jpg" }) {
      ...fluidImage
    }

    questionBankImage: file(relativePath: { eq: "questionBank.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stepUpImage: file(relativePath: { eq: "stepUp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const BannerCarousel = ({ data }): JSX.Element => {
  const mobile = useMediaQuery("(max-width: 525px)")

  return mobile ? (
    <Carousel onClick={[() => {}, () => {}, () => navigate("/enroll")]}>
      <Banner image={data.mobileBanner1.childImageSharp.fluid} alt="banner1" />
      <Banner image={data.mobileBanner2.childImageSharp.fluid} alt="banner2" />
      <Banner image={data.mobileBanner3.childImageSharp.fluid} alt="banner3" />
      <Banner image={data.mobileBanner4.childImageSharp.fluid} alt="banner4" />
      <Banner image={data.mobileBanner5.childImageSharp.fluid} alt="banner5" />
      <Banner image={data.mobileBanner6.childImageSharp.fluid} alt="banner6" />
      <Banner image={data.mobileBanner7.childImageSharp.fluid} alt="banner7" />
      <Banner image={data.mobileBanner8.childImageSharp.fluid} alt="banner7" />
    </Carousel>
  ) : (
    <Carousel onClick={[() => {}, () => {}, () => navigate("/enroll")]}>
      <Banner image={data.banner1.childImageSharp.fluid} alt="banner1" />
      <Banner image={data.banner2.childImageSharp.fluid} alt="banner2" />
      <Banner image={data.banner3.childImageSharp.fluid} alt="banner3" />
      <Banner image={data.banner4.childImageSharp.fluid} alt="banner4" />
      <Banner image={data.banner5.childImageSharp.fluid} alt="banner5" />
      <Banner image={data.banner6.childImageSharp.fluid} alt="banner6" />
      <Banner image={data.banner7.childImageSharp.fluid} alt="banner7" />
      <Banner image={data.banner8.childImageSharp.fluid} alt="banner8" />
    </Carousel>
  )
}

const IndexPage = ({ data }) => {
  const annoucements = [
    "FREE WEBINAR CLASSES WILL BE TAKEN EVERY SUNDAY",
    "COACHING FOR UPSC EXAM WILL BEGIN SHORTLY, KEEP FOLLOWING FOR MORE UPDATES",
    "SECOND BATCH FOR TNPSC EXAMS WILL BE STARTING SOON",
  ]

  const [modalIsOpen, setIsOpen] = useState(true)
  const mobile = useMediaQuery("(max-width: 525px)")

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const customStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      animation: "slideIn 1s ease-in-out forwards",
    },

    content: {
      maxWidth: "740px",
      minWidth: "240px",
      height: mobile ? "40%" : "60%",
      marginTop: mobile ? "10%" : "5%",
      margin: "auto",
      overflow: "hidden",
      padding: "2px",
      transform: "translateY(-1000000px)",
      animation: "slideDown 1s ease-in-out forwards",
      animationDelay: "0.3s",
    },
  }

  return (
    <Box>
      <SEO title="Home" />
      <Layout>
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: modalIsOpen ? "rgba(0, 0, 0, 0.5)" : "",
            }}
          >
            <div className={`modal-overlay`}>
              <div className={`modal-content`}>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyle}
                >
                  <IoMdClose
                    size={30}
                    style={{
                      color: "white",
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      cursor: "pointer",
                    }}
                    onClick={closeModal}
                  />
                  {mobile ? (
                    <img src={mobileModal} width="100%" height="100%" />
                  ) : (
                    <img src={modal} width="100%" height="100%" />
                  )}
                </Modal>
              </div>
            </div>
          </Box>
        </>
        <BannerCarousel data={data} />
        <Announcements data={annoucements} />
        <HeroFooter />
        <OurSpecialitySection />
        <MediaCoverage />
        <NewsAndEvents />
        <Mentors />
        <NumberSection />
        <ContactSection />
        <Cta />
      </Layout>
    </Box>
  )
}

export default IndexPage
