/** @jsx jsx */
import React from "react"
import { Link } from "gatsby"
import { Box, Text, Flex, jsx } from "theme-ui"
import { ArrowRightCircleFill } from "@emotion-icons/bootstrap/ArrowRightCircleFill"

const Cta = (): JSX.Element => {
  return (
    <Link sx={{ textDecoration: "none" }} to="/enroll">
      <Box data-sal="zoom-in" m={3} mt={[3, null, null, 6]} mb={3}>
        <Box
          p={3}
          sx={{
            borderRadius: "0.5em",
            maxWidth: "1400px",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            backgroundImage: "linear-gradient(to right, #FC9842, #FE5F75)",
            boxShadow: 0,
            cursor: "pointer",
            transition: "all 0.3s",
            "&:hover": {
              boxShadow: `0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)`,
            },
            "&:hover .arrowIcon": {
              transform: "translateX(50%)",
            },
          }}
        >
          <Flex
            color="white"
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Text mr={4} variant="caps" sx={{ fontSize: [1, null, 5] }}>
              Get Free Consultation from the Experts Now
            </Text>
            <span className="arrowIcon" sx={{ transition: "transform 0.3s" }}>
              <ArrowRightCircleFill size="2.5rem" />
            </span>
          </Flex>
        </Box>
      </Box>
    </Link>
  )
}

export default Cta
