import React from "react"
import { Link } from "gatsby"
import { Box, Grid, Button, Embed } from "theme-ui"
import VideoCard from "./VideoCard"
import ContentSection from "../ContentSection"

// images
import MediaBg from "../../../images/MediaBg.svg"

const MediaCoverage = (): JSX.Element => {
  return (
    <ContentSection
      header="Media Coverage"
    
      body={
        <Box
          sx={{
            backgroundImage: `url(${MediaBg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            marginTop:"-40px"
          }}
        >
          <Box
            data-sal="zoom-in"
            sx={{ maxWidth: "700px", margin: "auto", mb: [4,6] }}
          >
            <Embed src="https://www.youtube.com/embed/3DmaZYUqBfg" />
          </Box>
          <Grid
            columns={[1, null, null, 3]}
            sx={{
              margin: "auto",
              maxWidth: "1200px",
            }}
          >
            <Box data-sal="slide-right">
              <VideoCard
                url="https://www.youtube.com/embed/LpJIVLQQnK0"
                header="National Youth Day - Sri R. Natraj IPS Speech"
              />
            </Box>
            <Box data-sal="slide-up">
              <VideoCard
                url="https://www.youtube.com/embed/wbeM7NdszpE"
                header="Q & A session in ICAI talk by Sri R. Nataraj IPS"
              />
            </Box>
            <Box data-sal="slide-left">
              <VideoCard
                url="https://www.youtube.com/embed/gtx0ufgMXzU"
                header="An interesting conversation with Sri R. Natraj IPS - Part 2"
              />
            </Box>
          </Grid>
          <Box mt={4} sx={{ textAlign: "center" }}>
            <Link to="/videos">
              <Button
                sx={{
                  border: theme => `1px solid ${theme.colors.primary}`,
                  bg: "white",
                  color: "primary",
                  borderRadius: "3em",
                  px: 4,
                  fontSize:[1,3],
                  textAlign: "center",
                  marginBottom:3
                }}
              >
                See More
              </Button>
            </Link>
          </Box>
        </Box>
      }
    />
  )
}

export default MediaCoverage
