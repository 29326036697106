/** @jsx jsx */
import React from "react"
import { Box, jsx } from "theme-ui"
import { ChevronDown } from "@emotion-icons/boxicons-regular/ChevronDown"
import { keyframes } from "@emotion/core"
import scrollTo from "gatsby-plugin-smoothscroll"

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate(50%, 50%);
  }

  40%, 43% {
    transform: translate(50%, calc(50% - 20px));
  }

  70% {
    transform: translate(50%, calc(50% - 10px));
  }

  90% {
    transform: translate(50%, calc(50% - 3px));
  }
`

const HeroFooter = (): JSX.Element => {
  const onClick = () => {
    scrollTo("#scrollHead")
  }

  return (
    <Box mb={6} sx={{ position: "relative" }}>
      <Box bg="primary" sx={{ height: "35px" }}></Box>
      <Box id="scrollHead" bg="primary" sx={{ height: "5px" }}></Box>
      <button
        sx={{
          bg: "primary",
          borderRadius: "100%",
          width: "70px",
          height: "70px",
          padding: 0,
          border: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -20%)",
          "&:focus": {
            outline: "none",
          },
        }}
        onClick={onClick}
      >
        <span
          sx={{
            position: "absolute",
            bottom: "20px",
            right: "50%",
            transform: "translate(50%, 50%)",
            animation: `${bounce} 2s infinite`,
          }}
        >
          <ChevronDown size="3rem" color="white" />
        </span>
      </button>
    </Box>
  )
}

export default HeroFooter
