import { Box, Avatar, Text, Flex } from "theme-ui"
import React from "react"
import ContentSection from "../ContentSection"

// images
import NatarajanImage from "../../../images/Natarajan.png"
import VeerababuImage from "../../../images/Veerababu.png"
import MentorsBg from "../../../images/MentorsBg.svg"

interface MentorCardProps {
  image: string
  name: string
  role: string
  description: string
}

const MentorCard = (props: MentorCardProps) => {
  const { name, image, role, description } = props
  return (
    <Box
      sx={{
        boxShadow: 0,
        maxWidth: "300px",
        textAlign: "center",
        position: "relative",
      }}
      p={3}
      mt={3}
      bg="white"
    >
      <Avatar
        sx={{
          position: "absolute",
          top: 0,
          right: "50%",
          transform: "translate(50%, -50%)",
          boxShadow: 1,
        }}
        src={image}
      />
      <Text sx={{ fontWeight: "500" }} mt={5}>
        {name}
      </Text>
      <Text variant="secondary" mb={3} sx={{ fontSize: 0 }}>
        {role}
      </Text>
      <Text variant="secondary" sx={{fontSize:[0,1]}}>{description}</Text>
    </Box>
  )
}

const Mentors = (): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${MentorsBg})`,
        backgroundPosition: "0% -100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <ContentSection
        header="Mentors"
        body={
          <Flex
            sx={{
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "800px",
              margin: "auto",
            }}
          >
            <Box data-sal="slide-right">
              <MentorCard
                image={NatarajanImage}
                name="Dr. Natrajan IPS"
                role="Ex TNPSC Chairman"
                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages."
              />
            </Box>
          </Flex>
        }
      />
    </Box>
  )
}

export default Mentors
