/** @jsx jsx */
import React from "react"
import { Box, Grid, Flex, Heading, jsx } from "theme-ui"
import ContentSection from "../ContentSection"
import EventCard from "../EventCard"
import { Link, useStaticQuery, graphql } from "gatsby"
import { getPosts, getPostLink } from "../../utils/post"
import { PostInterface } from "../../types/post"

interface NewsCardProps {
  post: PostInterface
}

const NewsCard = (props: NewsCardProps) => {
  const { post } = props
  const { image, title } = post
  return (
    <Link to={getPostLink(post)}>
      <Box
        sx={{
          width: ["280px", "320px", "350px"],
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
          "&:hover .mainNewsImage": { transform: "scale(1.2)" },
          "&:hover .newsCardTitle": { opacity: 0 },
          "&:hover .newsCardReadArticle": { opacity: 1 },
        }}
      >
        <img
          className="mainNewsImage"
          sx={{
            width: "100%",
            objectFit: "cover",
            transition: "transform 0.3s",
          }}
          src={image.url + "&w=350"}
          alt="Media background"
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%)",
          }}
        />
        <Box
          className="newsCardTitle"
          p={2}
          sx={{
            bottom: "0",
            width: "100%",
            position: "absolute",
            opacity: 1,
            transition: "opacity 0.3s",
          }}
        >
          <Heading sx={{ fontSize: [1, 1, 1, 1] }} color="#FFFFFF">
            {title}
          </Heading>
        </Box>
        <Box
          className="newsCardReadArticle"
          sx={{
            position: "absolute",
            bottom: 0,
            fontSize: 3,
            textAlign: "center",
            width: "100%",
            opacity: 0,
            transition: "opacity 0.3s",
          }}
          p={2}
          bg="primary"
          color="white"
        >
          Read Full Article
        </Box>
      </Box>
    </Link>
  )
}

const NewsAndEvents = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      allPrismicPost(sort: { fields: data___date, order: DESC }, limit: 5) {
        edges {
          node {
            url
            data {
              title {
                text
              }
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      data {
                        name
                      }
                    }
                  }
                }
              }
              content {
                raw
              }
              date(formatString: "MMM D, YYYY")
              main_image {
                alt
                url
              }
              description {
                text
              }
            }
          }
        }
      }
    }
  `)

  const posts = getPosts(data)

  return (
    <ContentSection
      header="News & Events"
      body={
        <Flex
          mx={[4, 1]}
          mt={-4}
          sx={{
            flexDirection: ["column", null, null, null, "row"],
            justifyContent: ["space-around"],
          }}
        >
          <Grid sx={{ justifyItems: "center" }} gap={5} columns={[1, 2]}>
            <Box data-sal="zoom-out">
              <NewsCard post={posts[2]} />
            </Box>
            <Box data-sal="zoom-in">
              <NewsCard post={posts[3]} />
            </Box>
            <Box data-sal="zoom-in">
              <NewsCard post={posts[0]} />
            </Box>
            <Box data-sal="zoom-in">
              <NewsCard post={posts[1]} />
            </Box>
          </Grid>
          <Grid
            gap={[1, 1, 4]}
            columns={1}
            mt={[4, 3]}
            sx={{ maxWidth: "300px", margin: "auto" }}
          >
            <Box data-sal="slide-left">
              <EventCard month="SEP" date="31" event="Inauguration Session" />
            </Box>
            <Box data-sal="zoom-in">
              <EventCard month="OCT" date="1" event="UPSC Batch 1 Starts" />
            </Box>
            <Box data-sal="zoom-out">
              <EventCard month="OCT" date="10" event="Mock Test series" />
            </Box>
            <Box data-sal="slide-up">
              <EventCard month="OCT" date="20" event="Inauguration Session" />
            </Box>
          </Grid>
        </Flex>
      }
    />
  )
}
export default NewsAndEvents
