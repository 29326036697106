/** @jsx jsx */
import React from "react"
import Slider from "react-slick"
import { Box, jsx } from "theme-ui"

interface CarouselProps {
  children: React.ReactNode[]
  onClick: (() => void)[]
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 6000,
  appendDots: dots => {
    return <Box sx={{ position: "absolute", bottom: "10px" }}>{dots}</Box>
  },
}

/*  This is done as a workaround for click event triggering when swiping a slide.
 See this - https://github.com/akiran/react-slick/issues/604#issuecomment-526543085 */
class Carousel extends React.Component<CarouselProps> {
  swiping = false
  carouselRef = React.createRef()

  handleMouseDown = event => {
    event.preventDefault()
  }

  handleMouseUp = () => {
    this.swiping = this.carouselRef?.current?.innerSlider.state.swiping
  }

  handleClick = index => {
    if (!this.swiping) {
      const onClick = this.props.onClick[index]
      if (onClick) {
        onClick()
      }
    }
  }

  render() {
    return (
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Slider ref={this.carouselRef} {...settings}>
          {this.props.children.map((child, index) => (
            <div
              onClickCapture={() => this.handleClick(index)}
              onMouseUpCapture={this.handleMouseUp}
              onMouseDownCapture={this.handleMouseDown}
            >
              {child}
            </div>
          ))}
        </Slider>
      </Box>
    )
  }
}

export default Carousel
