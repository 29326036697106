/** @jsx jsx */
import React from "react"
import { Box, Grid, Heading, Text, jsx } from "theme-ui"
import ContentSection from "../ContentSection"

// images
import StudentFacultyIcon from "../../../images/studentFaculty.svg"
import LearningIcon from "../../../images/learning.svg"
import ChampionshipIcon from "../../../images/champion.svg"
import CollegeIcon from "../../../images/college.svg"

interface MetricProps {
  icon: string
  header: string
  subHeader: string
  description: string
}

const Metric = (props: MetricProps): JSX.Element => {
  const { icon, header, subHeader, description } = props
  return (
    <Box sx={{ textAlign: "center" }}>
      <Box
        sx={{
          borderRadius: "100%",
          height: "90px",
          width: "90px",
          marginLeft: "auto",
          marginRight: "auto",
          boxShadow: 0,
        }}
        mb={3}
        data-sal="zoom-in"
      >
        <img sx={{ maxWidth: "100%" }} src={icon} alt="metric icon" />
      </Box>
      <Heading mb={2} sx={{ fontSize: [2, 2, 4] }}>
        {header}
      </Heading>
      <Text mb={3} sx={{ fontSize: [1, 1, 3] }}>
        {subHeader}
      </Text>
      <Text sx={{ fontSize: [0, 0, 1] }} variant="secondary">
        {description}
      </Text>
    </Box>
  )
}

const NumberSection = (): JSX.Element => {
  return (
    <ContentSection
      header="By Numbers"
      body={
        <Box sx={{ maxWidth: "1300px" ,marginTop:["-40px","0px"]}}>
          <Grid gap={[3,3,5]} columns={[1, 2, 2, 4]}>
            <Metric
              icon={StudentFacultyIcon}
              header="50:1"
              subHeader="Student-to-faculty ratio"
              description="With our manageable size, Innovative teaching methods and flexible curriculum, we are able to provide the individualised approach every student Needs to excel in all aspects of his/her Education"
            />
            <Metric
              icon={LearningIcon}
              header="100+"
              subHeader="Mock Tests"
              description="Real exam like tests. The tests that we prepare have high % of repeated questions in the actual Official exams. This leads to Better results in the examinations"
            />
            <Metric
              icon={ChampionshipIcon}
              header="5"
              subHeader="Media coverage & awards"
              description="We are covered in media and have won several Awards and accolades in Our UPSC journey."
            />
            <Metric
              icon={CollegeIcon}
              header="100%"
              subHeader="Assistance to students"
              description="We provide 100% doubt clearance session to our students be it online or offline."
            />
          </Grid>
        </Box>
      }
    />
  )
}

export default NumberSection
